// 系统管理

const accountInfo = () => import('@/webapp/system/account-info.vue')
const accountSecure = () => import('@/webapp/system/account-secure.vue')
const memberRole = () => import('@/webapp/system/member-role.vue')
const memberList = () => import('@/webapp/system/member-list.vue')
const Api = require('../apis/path')
export default [
  {
    path: '/system',
    meta: {
      id: '80eb9139-4b31-4b9d-b2d0-d78c982874c7',
      icon: '',
      name: 'system',
      title: '系统管理',
      apis: [
      ]
    },
    children: [
      {
        path: '/account',
        meta: {
          id: '',
          icon: 'iconfont icon-zhanghaoshezhiicon',
          iconColor: '#527aee',
          name: 'account',
          title: '帐号设置',
          apis: [
          ]
        },
        children: [
          {
            path: '/info',
            meta: {
              id: '',
              icon: '',
              name: 'info',
              title: '个人信息',
              apis: [
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `get:${Api.path.user}/orga/{orgaId}/member`
              ]
            },
            component: accountInfo
          },
          {
            path: '/secure',
            meta: {
              id: '',
              icon: '',
              name: 'secure',
              title: '帐号安全',
              apis: [
              ]
            },
            component: accountSecure
          }
        ]
      },
      {
        path: '/member',
        meta: {
          id: '93c16a73-c28a-4bad-b6d2-5c149fb03f0f',
          icon: 'iconfont icon-yuangongzhongxinicon',
          iconColor: '#45acf6',
          name: 'member',
          title: '员工中心',
          apis: [
          ]
        },
        children: [
          {
            path: '/role',
            meta: {
              id: 'ae902ce5-dc38-40cb-99ab-f6fe9bbc532e',
              icon: '',
              name: 'role',
              title: '角色管理',
              apis: [
                `get:${Api.path.user}/operation`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `put:${Api.path.user}/orga/{orgaId}/role/{roleId}`,
                `post:${Api.path.user}/orga/{orgaId}/role`,
                `get:${Api.path.user}/orga/{orgaId}/role/{roleId}`,
                `delete:${Api.path.user}/orga/{orgaId}/role/{roleId}`
              ],
              actions: [
                {
                  id: '2e540118-7e5e-46a8-86c8-15a906b13334',
                  name: 'add',
                  title: '新增角色',
                  apis: []
                },
                {
                  id: '54eceeea-2003-4fce-a884-75862dbdb60c',
                  name: 'edit',
                  title: '编辑角色',
                  apis: []
                },
                {
                  id: '01a91346-b979-48f4-81a9-bbc072965504',
                  name: 'del',
                  title: '删除角色',
                  apis: []
                }
              ]
            },
            component: memberRole
          },
          {
            path: '/list',
            meta: {
              id: 'bafce3f8-3682-4e3a-a285-98f06b78a199',
              icon: '',
              name: 'member',
              title: '成员管理',
              apis: [
                `get:${Api.path.user}/orga/{orgaId}/department`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `post:${Api.path.user}/orga/{orgaId}/department`,
                `put:${Api.path.user}/orga/{orgaId}/department/{depId}`,
                `delete:${Api.path.user}/orga/{orgaId}/department/{depId}`,
                `get:${Api.path.user}/orga/{orgaId}/member`,
                `put:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `post:${Api.path.user}/orga/{orgaId}/member`,
                `get:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `delete:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `put:${Api.path.user}/orga/{orgaId}/member/{memberId}/status`
              ],
              actions: [
                {
                  id: 'e79aa018-69e9-4aa2-b66d-98ed6b6f5221',
                  name: 'add',
                  title: '新增员工',
                  apis: []
                },
                {
                  id: '8cd49bc2-30c7-44c2-bae2-83a146bad8f5',
                  name: 'edit',
                  title: '编辑员工',
                  apis: []
                },
                {
                  id: 'ce753012-c43d-4aef-8922-c38a489781bf',
                  name: 'del',
                  title: '删除员工',
                  apis: []
                },
                {
                  id: 'a980d80b-e229-44cd-8ba6-9b27fa380dfa',
                  name: 'stop',
                  title: '启用停用',
                  apis: []
                },
                {
                  id: '88f85c00-a0e9-42c3-b8fa-df51b7839596',
                  name: 'adddep',
                  title: '新增分类',
                  apis: []
                },
                {
                  id: 'd499af66-10fb-4721-8a08-3802c24134d8',
                  name: 'editdep',
                  title: '编辑分类',
                  apis: []
                },
                {
                  id: '673203c9-3347-4154-accf-e8edd7e74d95',
                  name: 'deldep',
                  title: '删除分类',
                  apis: []
                }
              ]
            },
            component: memberList
          }
        ]
      }
    ]
  }
]
