// 首页
const contentOverview = () => import('@/webapp/home/overview/content.vue')
const serviceOverview = () => import('@/webapp/home/overview/service.vue')
const examination = () => import('@/webapp/home/overview/examination.vue')
const userOverview = () => import('@/webapp/home/overview/user.vue')
const pageOverview = () => import('@/webapp/home/overview/page.vue')
const visit = () => import('@/webapp/home/overview/visit.vue')
const consult = () => import('@/webapp/home/overview/consult.vue')
const consultManual = () => import('@/webapp/home/overview/consult-manual.vue')
const overview = () => import('@/webapp/home/overview.vue')
const signOverview = () => import('@/webapp/home/sign-overview.vue')
const Api = require('../apis/path')
export default [
  {
    path: '/home',
    meta: {
      id: '1b86369f-a4f4-49e4-b586-ba57eec50b91',
      icon: '',
      name: 'home',
      title: '首页',
      apis: [
      ]
    },
    children: [
      {
        path: '/data-overview',
        meta: {
          id: 'e0dcd49e-fa5d-436f-a706-7a13f158fc54',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'overview',
          title: '总览',
          apis: [
            `get:${Api.path.knowledge}/index/data/platform`
          ]
        },
        component: overview
      },
      {
        path: '/content-overview',
        meta: {
          id: 'e0dcd49e-fa5d-436f-a706-7a13f158fc54',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'content-overview',
          title: '内容统计',
          apis: [
            `get:${Api.path.knowledge}/index/data/category/document/rinking`
          ]
        },
        component: contentOverview
      },
      {
        path: '/service-overview',
        meta: {
          id: '599fe8bb-29cb-4ce3-a1e5-0358426030b5',
          icon: 'iconfont icon-fuwutongjiicon',
          iconColor: '#ff9640',
          name: 'service-overview',
          title: '服务统计',
          apis: []
        },
        children: [
          {
            path: '/writ',
            meta: {
              id: '60d30764-6e08-4d92-83dc-474a004a7c3d',
              name: 'writ',
              title: '文书服务'
            },
            component: serviceOverview
          },
          {
            path: '/examination',
            meta: {
              id: 'e50a11c4-cbba-47e8-a542-17fc25285175',
              name: 'examination',
              title: '体检服务'
            },
            component: examination
          },
          {
            path: '/visit',
            meta: {
              id: '6ed6f08f-e36a-4d93-8ed4-57cff5ccd49d',
              name: 'visit',
              title: '上门服务',
              apis: []
            },
            component: visit
          },
          {
            path: '/consult',
            meta: {
              id: '80a37f18-28fa-44aa-95a5-eb40182cac02',
              name: 'consult',
              title: '咨询服务',
              apis: [
                `get:${Api.path.knowledge}/index/data/consult`,
                `get:${Api.path.knowledge}/index/data/consult/view`
              ]
            },
            component: consultManual
          }
        ]
      },
      {
        path: '/user-overview',
        meta: {
          id: '331015fb-f805-47ad-9b50-bfa3a6bd35d9',
          icon: 'iconfont icon-yonghutongjiicon',
          iconColor: '#ff7172',
          name: 'user-overview',
          title: '用户统计',
          apis: [
            `get:${Api.path.knowledge}/index/data/user`,
            `get:${Api.path.knowledge}/index/data/login`
          ]
        },
        component: userOverview
      },
      {
        path: '/page-overview',
        meta: {
          id: 'eb82793f-c1d0-4ed0-8b7f-a4e423d1cc68',
          icon: 'fa fa-file',
          iconColor: 'rgb(136 113 255)',
          name: 'page-overview',
          title: '页面分析',
          apis: [
            `get:${Api.path.knowledge}/event/log/count/button`,
            `get:${Api.path.knowledge}/event/log/count/page`,
            `get:${Api.path.knowledge}/event/log/count/platform`

          ]
        },
        component: pageOverview
      },
      {
        path: '/consult',
        meta: {
          id: 'afc94836-63ca-4bad-b5ad-563ef3d5272a',
          icon: 'fa fa-message',
          iconColor: '#4c7cff',
          name: 'consult',
          title: 'AI咨询分析',
          apis: [
            `get:${Api.path.knowledge}/index/data/ai`,
            `get:${Api.path.knowledge}/index/data/ai/trend`
          ]
        },
        component: consult
      },
      {
        path: '/sign-overview',
        meta: {
          id: '82a367fa-6103-4cfc-8305-a8adb9fd7fbc',
          icon: 'iconfont icon-qianshufenxi',
          iconColor: '#f5ad5d',
          name: 'sign-overview',
          title: '签署分析',
          apis: [
            `get:${Api.path.knowledge}/index/data/sign`
          ]
        },
        component: signOverview
      }
    ]

  }
]
