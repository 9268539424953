
// 用户管理
const personList = () => import('@/webapp/user/person-list.vue')
const personAuditList = () => import('@/webapp/user/person-audit-list.vue')
const orgaList = () => import('@/webapp/user/orga-list.vue')
const orgaAuditList = () => import('@/webapp/user/orga-audit-list.vue')
const channelList = () => import('@/webapp/user/channel-list.vue')
const publicValidateRecord = () => import('@/webapp/user/public-validate-record.vue')
const orgaAuditRecordList = () => import('@/webapp/user/orga-audit-record-list.vue')
const lawyerList = () => import('@/webapp/user/lawyer-list.vue')
const lawyerAudit = () => import('@/webapp/user/lawyer-audit-list.vue')
const lawyerAuditRecord = () => import('@/webapp/user/lawyer-audit-record-list.vue')
const Api = require('../apis/path')

export default [
  {
    path: '/user',
    meta: {
      id: '605e20dd-09c5-487b-a79f-d5ca946e9364',
      icon: '',
      name: 'user',
      title: '用户管理',
      apis: [
      ]
    },
    children: [
      {
        path: '/person',
        meta: {
          id: 'ee33d628-e461-4355-8b3b-43194f2bbca6',
          icon: 'iconfont icon-gerenyonghuguanliicon',
          iconColor: '#527aee',
          name: 'person',
          title: '个人用户管理',
          apis: [

          ]
        },
        children: [
          {
            path: '/list',
            meta: {
              id: '6bc940a6-ea91-4492-8762-b16b498a49b1',
              icon: '',
              name: 'list',
              title: '个人用户列表',
              apis: [
                `get:${Api.path.user}/user`,
                `put:${Api.path.user}/user/{userId}/status`,
                `put:${Api.path.user}/user/{userId}/card`,
                `get:${Api.path.user}/user/{userId}`,
                `delete:${Api.path.user}/user/{userId}`
              ],
              actions: [
                {
                  id: '509aca51-e55c-430d-95de-fbe36d6446b4',
                  name: 'stop',
                  title: '停用/启用',
                  apis: []
                }
                // {
                //   id: '0389c928-3f03-479d-8da4-52490cbdf00e',
                //   name: 'del',
                //   title: '删除',
                //   apis: []
                // }

              ]

            },
            component: personList
          },
          {
            path: '/audit',
            meta: {
              id: '860cebd9-23a1-4f4d-8540-0c4c16e1f227',
              icon: '',
              name: 'audit',
              title: '待审核认证',
              apis: [
                `get:${Api.path.user}/user/auth`,
                `put:${Api.path.user}/user/{userId}/auth`
              ],
              actions: [
                {
                  id: '59e2fe44-14ad-49e4-ae6e-8427630f6113',
                  name: 'auth',
                  title: '审核',
                  apis: []
                }
              ]
            },
            component: personAuditList
          }
        ]
      },
      {
        path: '/lawyer',
        meta: {
          id: 'ec1ea2c3-831c-46dc-8df4-396c0a2a7cf9',
          icon: 'iconfont icon-gerenyonghuguanliicon',
          iconColor: '#527aee',
          name: 'lawyer',
          title: '律师用户管理'
        },
        children: [
          {
            path: '/list',
            meta: {
              id: '4d3a6e95-4747-4d98-88b2-e73a344e31f7',
              icon: '',
              name: 'list',
              title: '律师用户列表',
              apis: [
                `put:${Api.path.user}/user/{userId}/status`,
                'get:/operation/v1/lawyer',
                'get:/operation/v1/lawyer/{userId}'
              ],
              actions: [
                {
                  id: '055e4c49-9fdf-4bf1-91de-fe71decd4402',
                  name: 'stop',
                  title: '停用/启用',
                  apis: []
                }
                // {
                //   id: '84df60e0-1eb2-4486-b324-5a63a8c5c247',
                //   name: 'del',
                //   title: '删除',
                //   apis: []
                // }
              ]
            },
            component: lawyerList
          },
          {
            path: '/audit',
            meta: {
              id: 'd64b07d3-ef09-44b2-a284-3dd0a86edd86',
              icon: '',
              name: 'auth',
              title: '待审核认证',
              badge: 'lawyerAuthCount',
              apis: [
                'get:/operation/v1/lawyer/auth',
                'put:/operation/v1/lawyer/auth/{id}'
              ],
              actions: [
                { id: '383d74e2-ba66-4c10-9260-6a82539db2bb', name: 'audit', title: '审核' }
              ]
            },
            component: lawyerAudit
          },
          {
            path: '/audit-record',
            meta: {
              id: 'c014f95e-a146-4b95-bd8e-4d470fe5d54b',
              icon: '',
              name: 'audit-record',
              title: '审核记录',
              apis: [
                'get:/operation/v1/lawyer/auth'
              ]

            },
            component: lawyerAuditRecord
          }
        ]
      },
      {
        path: '/orga',
        meta: {
          id: '7056e8d9-83a2-4775-a9bb-356ed4ff822b',
          icon: 'iconfont icon-qiyeyonghuguanliicon',
          iconColor: '#00cde0',
          name: 'orga',
          title: '企业用户管理',
          apis: [
          ]
        },
        children: [
          {
            path: '/list',
            meta: {
              id: '28a837a3-15ee-464d-856d-4ae2392002b8',
              icon: '',
              name: 'list',
              title: '企业用户列表',
              apis: [
                `get:${Api.path.user}/orga`,
                `get:${Api.path.user}/orga/{id}`,
                `get:${Api.path.user}/orga/{orgaid}/member`,
                `put:${Api.path.user}/orga/{id}`,
                `post:${Api.path.user}/orga`,
                `put:${Api.path.user}/orga/{id}/admin`,
                `put:${Api.path.user}/orga/{id}/status`,
                `delete:${Api.path.user}/orga/{id}`,
                `get:${Api.path.user}/orga/{id}/role`,
                `get:${Api.path.knowledge}/questionnaire/org/{orgaId}`,
                `get:${Api.path.knowledge}/questionnaire/report`,
                `get:${Api.path.knowledge}/questionnaire/online/to/estimate/{id}`
              ],
              actions: [
                {
                  id: '5b5c6570-10b4-4022-90ee-5624358a1ceb',
                  name: 'add',
                  title: '新增',
                  apis: []
                },
                {
                  id: 'e08f69eb-fb8e-4bf9-84b9-949f05cf3749',
                  name: 'edit',
                  title: '编辑',
                  apis: []
                },
                {
                  id: '70d11241-b1fb-47ee-98f4-c4c78532f02d',
                  name: 'stop',
                  title: '停用/启用',
                  apis: []
                },
                {
                  id: '42805ce1-996f-4401-854b-3bb01a6e5306',
                  name: 'del',
                  title: '删除',
                  apis: []
                }, {
                  id: '3815cecf-3d63-4ca1-b7b9-c1ce211eee47',
                  name: 'addmember',
                  title: '添加子帐号',
                  apis: [
                    `post:${Api.path.user}/orga/{id}/member`
                  ]
                }
              ]
            },
            component: orgaList
          },
          {
            path: '/audit',
            meta: {
              id: '0e333a87-22ce-4df5-9bf8-99e97827e04d',
              icon: '',
              name: 'audit',
              title: '待审核认证',
              badge: 'missive',
              apis: [
                `get:${Api.path.user}/orga/auth`,
                `put:${Api.path.user}/orga/{orgaId}/auth`,
                'get:/operation/v1/orga/auth/orga/missive',
                'put:/operation/v1/orga/auth/orga/missive',
                'get:/operation/v1/user/{id}'
              ],
              actions: [
                {
                  id: '7545db7a-0ae8-470c-bda5-5fa43abb530b',
                  name: 'auth',
                  title: '审核',
                  apis: []
                }
              ]
            },
            component: orgaAuditList
          },
          {
            path: '/audit-record',
            meta: {
              id: 'c9ba8321-9671-4d35-a813-e3c7bbad4ee0',
              icon: '',
              name: 'audit-record',
              title: '审核记录',
              apis: [
                'get:/operation/v1/orga/auth/orga/missive/log',
                'get:/operation/v1/orga/auth/orga/missive/log/{id}'
              ],
              actions: [
              ]
            },
            component: orgaAuditRecordList
          },
          {
            path: '/public-validate-record',
            meta: {
              id: '2b25a486-536e-4d09-a5fe-5cdfa54e58bf',
              icon: '',
              name: 'public-validate-record',
              title: '对公验证记录',
              apis: [
                'get:/operation/v1/orga/auth/orga/transfer',
                'get:/operation/v1/common/dict/bank'
              ],
              actions: [
              ]
            },
            component: publicValidateRecord
          }
        ]
      },
      {
        path: '/channel',
        meta: {
          id: '595c1795-3468-4b57-9551-351a42913f1b',
          icon: 'iconfont icon-qiyeyonghuguanliicon',
          iconColor: '#00cde0',
          name: 'channel',
          title: '渠道用户管理',
          apis: [
            `get:${Api.path.user}/orga`,
            `get:${Api.path.user}/orga/{orgaid}/member`,
            `put:${Api.path.user}/orga/{id}`,
            `post:${Api.path.user}/orga`,
            `put:${Api.path.user}/orga/{id}/admin`,
            `put:${Api.path.user}/orga/{id}/status`,
            `delete:${Api.path.user}/orga/{id}`,
            `get:${Api.path.user}/orga/{id}/role`,
            `get:${Api.path.user}/orga/{id}/channel/orgas`,
            `get:${Api.path.user}/orga/{id}/channel/orgas/{id}`,
            `get:${Api.path.user}/orga/{id}/channel/users`,
            `get:${Api.path.user}/orga/{id}/channel/users/{id}`
          ],
          actions: [
            { id: '3d83baa4-8f26-46e7-9fac-f4bedae817d8', name: 'add', title: '新增' },
            { id: '64f7251f-4780-4ef4-8bf1-82f23840ff96', name: 'edit', title: '编辑' },
            { id: '37727408-d343-4bf2-bc75-adce83d6c826', name: 'stop', title: '停用/启用' },
            { id: '0353b44f-66f8-4116-8c17-e777dbf625c6', name: 'del', title: '删除' }
          ]
        },
        component: channelList
      }
    ]
  }
]
