import GreyList from './gray-list'
import WhiteList from './white-list'
import userRoute from './user'
import homeRoute from './home'
import systemRoute from './system'
import contentRoute from './content'
import orderRoute from './order'
import serviceRoute from './service'
import marketRoute from './market'
import evaluate from './evaluate.js'
import message from './message.js'

const routerList = [
  ...homeRoute,
  ...contentRoute,
  ...userRoute,
  ...marketRoute,
  ...serviceRoute,
  ...orderRoute,
  ...evaluate,
  ...message,
  ...systemRoute
]

export default { GreyList, WhiteList, routerList }
