// 服务管理
const contractManage = () => import('@/webapp/service/docs/contract-manage.vue')
const contractReview = () => import('@/webapp/service/docs/contract-review.vue')
const contractAllReview = () => import('@/webapp/service/docs/all-contract-review.vue')
const contractComparison = () => import('@/webapp/service/docs/contract-comparison.vue')
const serviceContractView = () => import('@/webapp/service/manage/contract-view.vue')
const serviceContractGhostWrite = () => import('@/webapp/service/manage/contract-ghostwrite.vue')
// const serviceContractModify = () => import('@/webapp/service/manage/contract-modify.vue')
const serivceRecord = () => import('@/webapp/service/manage/record.vue')
const serivceRule = () => import('@/webapp/service/manage/rule.vue')
const examinationAssessmentSelf = () => import('@/webapp/service/examination/assessment-self.vue')
const examinationAssessmentAll = () => import('@/webapp/service/examination/assessment-all.vue')
const examinationPush = () => import('@/webapp/service/examination/push.vue')
const vistiRecord = () => import('@/webapp/service/visit/index.vue')
const vistiAllRecord = () => import('@/webapp/service/visit/all.vue')
const consultationRecord = () => import('@/webapp/service/consultation/index.vue')
const consultationAllRecord = () => import('@/webapp/service/consultation/all.vue')

const Api = require('../apis/path')
export default [
  {
    path: '/service',
    meta: {
      id: '64329ed7-c168-4374-86d1-279aad24436e',
      icon: '',
      name: 'service',
      title: '服务中心',
      apis: [
      ]
    },
    children: [
      {
        path: '/docs',
        meta: {
          id: 'c93deb6c-c82a-4728-993e-7e82e3809f81',
          icon: 'iconfont icon-wendangguanliicon',
          iconColor: '#ff9640',
          name: 'docs',
          title: '文档管理'
        },
        children: [
          {
            path: '/contract-manage',
            meta: {
              id: '763f50e4-8554-4bd9-b3b0-a7d9fb3d47c7',
              icon: '',
              name: 'manage',
              title: '合同管理',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/category/list/{id}`,
                `post:${Api.path.knowledge}/category`,
                `delete:${Api.path.knowledge}/category/{id}`,
                `put:${Api.path.knowledge}/category/{id}`,
                `get:${Api.path.knowledge}/law/agreement/page/list`,
                `post:${Api.path.knowledge}/law/agreement`,
                `put:${Api.path.knowledge}/law/agreement/{id}`,
                `delete:${Api.path.knowledge}/law/agreement/{id}`,
                `get:${Api.path.knowledge}/knowledge/v1/law/agreement/recycle/page/list`,
                `delete:${Api.path.knowledge}/law/agreement/all`,
                `put:${Api.path.knowledge}/law/agreement/batch`,
                `delete:${Api.path.knowledge}/law/agreement/batch`,
                `get:${Api.path.knowledge}/law/agreement/{id}`,
                `get:${Api.path.knowledge}/law/agreement/recycle/page/list`,
                `delete:${Api.path.knowledge}/law/agreement/clear/{id}`,
                `put:${Api.path.knowledge}/law/agreement/recover/{id}`,
                `delete:${Api.path.knowledge}/law/agreement/batch/recover`
              ]
            },
            component: contractManage
          },
          {
            path: '/contract-comparison',
            meta: {
              id: 'c089e362-967d-4d44-8ada-49f2d433d54b',
              icon: '',
              name: 'comparison',
              title: '合同比对',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/agreement/page/list/search`
              ]
            },
            component: contractComparison
          }
        ]
      },
      {
        path: '/internal-review',
        meta: {
          id: '15637b6d-1ac1-434c-9ea9-980dd2416093',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'contractReview',
          title: '内部审查'
        },
        children: [
          {
            path: '/my-review',
            name: 'my-review',
            meta: {
              id: '45543a59-4413-462a-9c0a-68cc70a76514',
              icon: '',
              name: 'review',
              title: '我的审查',
              apis: [
                `get:${Api.path.knowledge}/law/agreement/flow/page/list`,
                `get:${Api.path.knowledge}/law/agreement/flow/{id}`,
                `put:${Api.path.knowledge}/law/agreement/flow/{id}`,
                `post:${Api.path.knowledge}/law/agreement/flow/flow`,
                `post:${Api.path.knowledge}/law/agreement/flow/flow/agreement`,
                `post:${Api.path.knowledge}/law/agreement/flow/flow/agreement`,
                `get:${Api.path.knowledge}/category/all`,
                `put:${Api.path.knowledge}/law/agreement/flow/annex/{id}`
              ],
              badge: 'contractReviewCount',
              actions: [
                {
                  id: 'f4eacda7-e5d0-4e33-a34d-06076c24a94f',
                  name: 'add',
                  title: '添加合同审查',
                  apis: [
                    `post:${Api.path.knowledge}/law/agreement/flow`
                  ]
                }
              ]
            },
            component: contractReview
          },
          {
            path: '/all-review',
            name: 'all-review',
            meta: {
              id: 'd7eb7f22-e321-4517-b921-375d17cca569',
              icon: '',
              name: 'all-review',
              title: '所有审查',
              apis: [
                `get:${Api.path.knowledge}/law/agreement/flow/page/list`,
                `get:${Api.path.knowledge}/law/agreement/flow/{id}`,
                `get:${Api.path.knowledge}/category/all`
              ]
            },
            component: contractAllReview
          }
        ]
      },
      {
        path: '/manage',
        meta: {
          id: '9f431797-86c2-4039-8db1-d6415fd0723b',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'manage',
          title: '文件服务'
        },
        children: [
          {
            path: '/contract-ghostwrite',
            meta: {
              id: '11a81a13-8559-4b65-81c7-6b37c9929782',
              icon: '',
              name: 'contract-ghostwrite',
              title: '我的代写合同',
              badge: 'contractGhostwriteCount',
              apis: [
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/law/agreement/service/page/list`,
                `post:${Api.path.knowledge}/law/agreement/service/accept/{serviceId}`,
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/agreement/service/{id}`,
                `get:${Api.path.knowledge}/law/document/{id}`,
                `put:${Api.path.knowledge}/law/agreement/service/remark/{serviceId}`,
                `put:${Api.path.knowledge}/law/agreement/service/annex/{id}`,
                `get:${Api.path.knowledge}/user/services/num`,
                `get:${Api.path.user}/orga/{orgaId}/member`,
                `get:${Api.path.user}/orga`,
                `post:${Api.path.knowledge}/law/agreement/service/initiate`,
                `post:${Api.path.knowledge}/law/agreement/service/generate/{id}`
              ],
              actions: [
                {
                  id: '23a8f3df-6f50-4368-bd4c-342582db2b7a',
                  name: 'add',
                  title: '创建服务',
                  apis: []
                },
                {
                  id: 'd4c33f53-7289-4b33-8838-ac95a09cace8',
                  name: 'build',
                  title: '生成评价单',
                  apis: []
                }
              ]
            },
            component: serviceContractGhostWrite

          },
          {
            path: '/contract-view',
            meta: {
              id: '282f0254-4316-48fc-b95e-a84198d11629',
              name: 'contract-view',
              title: '我的代审合同',
              badge: 'contractViewCount',
              apis: [
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/law/agreement/service/page/list`,
                `post:${Api.path.knowledge}/law/agreement/service/accept/{serviceId}`,
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/agreement/service/{id}`,
                `get:${Api.path.knowledge}/law/document/{id}`,
                `put:${Api.path.knowledge}/law/agreement/service/remark/{serviceId}`,
                `put:${Api.path.knowledge}/law/agreement/service/annex/{id}`,
                `get:${Api.path.knowledge}/user/services/num`,
                `get:${Api.path.user}/orga/{orgaId}/member`,
                `get:${Api.path.user}/orga`,
                `post:${Api.path.knowledge}/law/agreement/service/initiate`,
                `post:${Api.path.knowledge}/law/agreement/service/generate/{id}`
              ],
              actions: [
                {
                  id: 'd8aa6ee1-1e84-445d-ae5a-2e8ffac878bb',
                  name: 'add',
                  title: '创建服务',
                  apis: []
                },
                {
                  id: '74c3b484-a32a-40f3-973d-a91f0811f524',
                  name: 'build',
                  title: '生成评价单',
                  apis: []
                }
              ]
            },
            component: serviceContractView
          },
          // {
          //   path: '/contract-modify',
          //   meta: {
          //     id: '5e70e348-45e7-4b39-b8e5-1f476fbb1c5f',
          //     icon: '',
          //     name: 'contract-modify',
          //     title: '合同修改',
          //     apis: [
          //       `get:${Api.path.user}/orga/{id}/member`,
          //       `get:${Api.path.knowledge}/law/agreement/service/page/list`,
          //       `post:${Api.path.knowledge}/law/agreement/service/accept/{serviceId}`,
          //       `get:${Api.path.knowledge}/category/all`,
          //       `get:${Api.path.knowledge}/law/agreement/service/{id}`,
          //       `get:${Api.path.knowledge}/law/document/{id}`,
          //       `put:${Api.path.knowledge}/law/agreement/service/remark/{serviceId}`,
          //       `put:${Api.path.knowledge}/law/agreement/service/annex/{id}`
          //     ]
          //   },
          //   component: serviceContractModify
          // },
          {
            path: '/record',
            meta: {
              id: 'c7033122-6485-4f35-b329-4a517e6e99c5',
              icon: '',
              name: 'record',
              title: '所有文件服务',
              apis: [
                `get:${Api.path.knowledge}/law/agreement/service/page/list`,
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/agreement/service/{id}`
              ]
            },
            component: serivceRecord
          }
        ]
      },
      {
        path: '/examination',
        meta: {
          id: 'b68b6ca4-6bc5-4eb2-aae6-b1fbcc9d3a06',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'examination',
          title: '体检服务'
        },
        children: [
          {
            path: '/assessment',
            meta: {
              id: '82992b45-fdbc-46cd-afb7-63762da05c1d',
              icon: '',
              iconColor: '',
              name: 'assessment',
              title: '我的体检评估',
              badge: 'risks',
              apis: [
                `get:${Api.path.knowledge}/questionnaire/estimate/page`,
                `put:${Api.path.knowledge}/questionnaire/switches/{id}`,
                `get:${Api.path.knowledge}/questionnaire/report`,
                `post:${Api.path.knowledge}/questionnaire/online/to/estimate/{id}`,
                `post:${Api.path.knowledge}/questionnaire/online/estimate`,
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/questionnaire/estimate/count`
              ]
            },
            component: examinationAssessmentSelf
          },
          {
            path: '/assessment-all',
            meta: {
              id: '93372152-d286-40dd-a2c7-121165462656',
              icon: '',
              iconColor: '',
              name: 'assessment-all',
              title: '所有评估',
              apis: [
                `get:${Api.path.knowledge}/questionnaire/estimate/page`,
                // `put:${Api.path.knowledge}/questionnaire/switches/{id}`,
                `get:${Api.path.knowledge}/questionnaire/report`,
                `post:${Api.path.knowledge}/questionnaire/online/to/estimate/{id}`,
                `post:${Api.path.knowledge}/questionnaire/online/estimate`,
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/questionnaire/estimate/count`
              ]
            },
            component: examinationAssessmentAll
          },
          {
            path: '/push',
            meta: {
              id: 'a56e8c25-34dc-445f-b4a4-abe48e427572',
              icon: '',
              iconColor: '',
              name: 'push',
              title: '问卷推送',
              apis: [
                `get:${Api.path.knowledge}/questionnaire/push/page`,
                `put:${Api.path.knowledge}/questionnaire/push`,
                `get:${Api.path.knowledge}/questionnaire/all`,
                `put:${Api.path.knowledge}/questionnaire/push/cancel/{id}`,
                `get:${Api.path.knowledge}/questionnaire/push/info/{id}`,
                `put:${Api.path.knowledge}/questionnaire/push/questionnaire`,
                `get:${Api.path.knowledge}/questionnaire/push/questionnaire/info/{id}`,
                `get:${Api.path.knowledge}/questionnaire/push/page/count`
              ]
            },
            component: examinationPush
          }
        ]
      },
      {
        path: '/visit',
        meta: {
          id: '1ad6d5d6-3c87-4bcc-8a80-ffdc64bb220b',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'visit',
          title: '上门服务'
        },
        children: [
          {
            path: '/record',
            meta: {
              id: '153c840f-f467-482d-8ac6-0b9eeaa66947',
              icon: '',
              iconColor: '',
              name: 'record',
              title: '我的上门服务',
              badge: 'onsite',
              apis: [
                `get:${Api.path.knowledge}/user/onsite/service/page/list`,
                `get:${Api.path.knowledge}/user/onsite/service/{id}`,
                `post:${Api.path.knowledge}/user/onsite/service`,
                `put:${Api.path.knowledge}/user/onsite/service/{id}`,
                `delete:${Api.path.knowledge}/user/onsite/service/{id}`,
                `get:${Api.path.knowledge}/user/onsite/service/count`,
                `put:${Api.path.knowledge}/user/onsite/service/cancel/{id}`,
                `put:${Api.path.knowledge}/user/onsite/service/begin/{id}`,
                `put:${Api.path.knowledge}/user/onsite/service/submit/{id}`,
                `get:${Api.path.knowledge}/user/services/num`,
                `post:${Api.path.knowledge}/user/onsite/service/generate/{id}`
              ],
              actions: [
                {
                  id: '137e5b30-d67a-4710-9a90-9d681a1464a9',
                  name: 'add',
                  title: '创建服务'
                },
                {
                  id: 'c2cf6c87-548c-4847-93e0-7e205dcecd83',
                  name: 'build',
                  title: '生成评价单',
                  apis: []
                }
              ]
            },
            component: vistiRecord
          },
          {
            path: '/all-record',
            meta: {
              id: 'a4bc2d90-38d1-48ad-b7d8-cc0bc6d0a945',
              icon: '',
              iconColor: '',
              name: 'all-record',
              title: '所有上门服务',
              apis: [
                `get:${Api.path.knowledge}/user/onsite/service/page/list`,
                `get:${Api.path.knowledge}/user/onsite/service/{id}`,
                `get:${Api.path.knowledge}/user/onsite/service/count`
              ]
            },
            component: vistiAllRecord
          }
        ]
      },
      {
        path: '/consultation',
        meta: {
          id: 'd15b9c7a-f3da-4ba2-933a-882e5e9de1ca',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'consultation',
          title: '咨询服务'
        },
        children: [
          {
            path: '/record',
            meta: {
              id: '355d9410-5342-422f-becd-bff7e5789899',
              icon: '',
              iconColor: '',
              name: 'record',
              title: '我的咨询接待',
              badge: 'consult',
              apis: [
                `get:${Api.path.knowledge}/user/consult/mine/count`,
                `get:${Api.path.knowledge}/user/consult/mine/page/list`,
                `get:${Api.path.knowledge}/user/consult/{id}`,
                `put:${Api.path.knowledge}/user/consult/{id}`,
                `post:${Api.path.knowledge}/user/consult/generate/{id}`
              ],
              actions: [
                {
                  id: 'fa0de473-1ff0-4298-ae31-d57281930dc2',
                  name: 'build',
                  title: '生成评价单',
                  apis: []
                }
              ]
            },
            component: consultationRecord
          },
          {
            path: '/all-record',
            meta: {
              id: 'ab505638-76cd-4140-963d-2607320649e5',
              icon: '',
              iconColor: '',
              name: 'all-record',
              title: '所有咨询',
              apis: [
                'get:/knowledge/user/consult/page/list',
                'get:/knowledge/user/consult/{id}',
                'get:/knowledge/user/consult/count'
              ]
            },
            component: consultationAllRecord
          },
          {
            path: '/ent-record',
            meta: {
              id: 'ab5f4244-b845-46a2-8ae1-41ceb5a6c5e3',
              name: 'ent-record',
              title: '企业咨询',
              apis: [
                `get:${Api.path.user}/orga`,
                `post:${Api.path.knowledge}/org/consult`,
                `get:${Api.path.knowledge}/org/consult/page/list`,
                `get:${Api.path.knowledge}/org/consult/{id}`,
                `delete:${Api.path.knowledge}/org/consult/{id}`
              ],
              actions: [
                {
                  id: '3e568a64-844f-4d6c-be3a-f86880918346',
                  name: 'add',
                  title: '添加服务',
                  apis: []
                },
                {
                  id: '1cfcd873-1906-41e3-ae28-09681c5dcad2',
                  name: 'del',
                  title: '删除服务',
                  apis: []
                }
              ]
            },
            component: () => import('@/webapp/service/consultation/ent.vue')
          }
        ]
      },
      {
        path: '/offline-service',
        meta: {
          id: '36ad51a5-a0eb-441c-9a5f-944a5aefac37',
          icon: 'iconfont icon-zonglanicon2',
          iconColor: '#507bfd',
          name: 'offline-service',
          title: '线下服务',
          apis: [
            `get:${Api.path.user}/orga`,
            `get:${Api.path.user}/user`,
            `get:${Api.path.user}/orga/{id}/member`,
            `get:${Api.path.knowledge}/law/offline/service/page/list`,
            `post:${Api.path.knowledge}/law/offline/service`,
            `get:${Api.path.knowledge}/law/offline/service/{id}`,
            `delete:${Api.path.knowledge}/law/offline/service/{id}`,
            `post:${Api.path.knowledge}/law/offline/service/generate/{id}`
          ]
        },
        component: () => import('@/webapp/service/offline/index.vue')
      },
      {
        path: '/service-settings',
        meta: {
          id: '9561b485-bd7d-4492-9853-154bb3c05d87',
          icon: 'iconfont icon-dingdanguanliicon',
          iconColor: '#507bfd',
          name: 'service-settings',
          title: '服务设置'
        },
        children: [
          {
            path: '/rule',
            meta: {
              id: '9163cf6e-0b7e-4a29-b6cc-a0dde28ba4b5',
              icon: '',
              name: 'rule',
              title: '服务规则',
              apis: [
                `get:${Api.path.user}/orga/{id}/member`,
                `put:${Api.path.user}/orga/{id}/member/{memberId}/counselor`,
                `put:${Api.path.user}/orga/{id}/member/{memberId}/counselor/auto`,
                `get:${Api.path.user}/orga`,
                `put:${Api.path.user}/orga/{id}/member/{memberId}/service/orga`
              ]
            },
            component: serivceRule
          },
          {
            path: '/card',
            meta: {
              id: '16d7ee95-8c30-4114-b9b5-f6efe93f21ed',
              icon: '',
              name: 'card',
              title: '解卡线索',
              apis: [
                'get:/knowledge/v1/law/cart/question/page/list',
                'delete:/knowledge/v1/law/cart/question/{id}',
                'get:/knowledge/v1/law/cart/question/bd/page/list',
                'delete:/knowledge/v1/law/cart/question/bd/{id}'
              ]
            },
            component: () => import('@/webapp/service/manage/card.vue')
          }
        ]
      }

    ]

  }
]
