import Api from '@/apis/index.js'
export default class Server extends Api {
  /**
   * 获取当前登录用户信息
   * @param {*} params
   * @returns
   */
  async getCurrentUser () {
    return await this.$http.get(`${Api.path.user}/user/current`)
  }

  async login (username, password) {
    return await this.$http.post('/api/login', { type: 'platform', phone: username, password }, { params: { device: 'web:platform' } })
  }

  async refreshToken (payload) {
    return await this.$http.post('/api/refresh_token', { payload: payload, headers: payload })
  }

  /**
   * 获取图型验证码
   * @returns
   */
  async getCode () {
    return await this.$http.get(`${Api.path.user}/common/code/image`)
  }
}
