const Api = require('../apis/path')
export default [
  {
    path: '/evaluate',
    meta: {
      id: '795b4b51-2b72-4647-bb7e-5f8f4a0e666d',
      icon: '',
      name: 'evaluate',
      title: '评价中心',
      apis: [
      ]
    },
    children: [
      {
        path: '/manage',
        meta: {
          id: 'f1256fd7-6e5e-473b-8f58-f79b823a1410',
          icon: 'iconfont icon-zonglanicon2',
          iconColor: '#507bfd',
          name: 'manage',
          title: '评价管理',
          apis: [
          ]
        },
        children: [
          {
            path: '/person',
            meta: {
              id: '7c3e8bef-fbf4-4157-a692-e7c710d7f99e',
              name: 'person',
              title: '个人评价',
              apis: [
                `get:${Api.path.user}/orga`,
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/law/evaluate/service/page/list`,
                `get:${Api.path.knowledge}/law/evaluate/service/count`,
                `get:${Api.path.knowledge}/law/evaluate/service/{id}`
              ]
            },
            component: () => import('@/webapp/evaluate/manage-person.vue')
          },
          {
            path: '/orga',
            meta: {
              id: '8f7c377f-9672-4dbb-a72e-8e824aca8f8f',
              name: 'orga',
              title: '企业评价',
              apis: [
                `get:${Api.path.user}/orga`,
                `get:${Api.path.user}/orga/{id}/member`,
                `get:${Api.path.knowledge}/law/evaluate/service/page/list`,
                `get:${Api.path.knowledge}/law/evaluate/service/count`,
                `get:${Api.path.knowledge}/law/evaluate/service/{id}`
              ]
            },
            component: () => import('@/webapp/evaluate/manage-orga.vue')
          }
        ]
      },
      {
        path: '/statistic',
        meta: {
          id: '6cb8e89e-70b8-4997-a187-c25afc1fda87',
          icon: 'iconfont icon-zonglanicon2',
          iconColor: '#507bfd',
          name: 'statistic',
          title: '评价统计',
          apis: [
            `get:${Api.path.knowledge}/law/evaluate/service/statistics`,
            `get:${Api.path.knowledge}/law/evaluate/service/statistics/info/examiner/{examinerId}/bizType/{bizType}`
          ]
        },
        component: () => import('@/webapp/evaluate/statistic.vue')
      }
    ]
  }
]
