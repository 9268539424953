const Api = require('../apis/path')
export default [
  {
    path: '/message',
    meta: {
      id: '05453fa3-7ff4-4f94-a472-151dd6288402',
      icon: '',
      name: 'message',
      title: '消息中心'
    },
    children: [
      {
        path: '/manage',
        meta: {
          id: 'b833356d-cb75-456a-8730-2143145b3ac2',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'manage',
          title: '消息管理'
        },
        children: [
          {
            path: '/person',
            meta: {
              id: '10a31d01-7af9-489e-ae59-fb738faf158a',
              icon: '',
              iconColor: '',
              name: 'person',
              title: '个人消息',
              apis: [
                `get:${Api.path.knowledge}/msg/module/page/list`,
                `post:${Api.path.knowledge}/msg/module`,
                `get:${Api.path.knowledge}/msg/module/{id}`,
                `get:${Api.path.knowledge}/msg/module/users/{id}`,
                `get:${Api.path.knowledge}/user/org/auth`,
                `get:${Api.path.knowledge}/user/user/auth`
              ]
            },
            component: () => import('@/webapp/message/person-list.vue')
          },
          {
            path: '/orga',
            meta: {
              id: 'd4b85d20-a555-4181-9866-2ffb6580587f',
              icon: '',
              iconColor: '',
              name: 'orga',
              title: '企业消息',
              apis: [
                `get:${Api.path.knowledge}/msg/module/page/list`,
                `post:${Api.path.knowledge}/msg/module`,
                `get:${Api.path.knowledge}/msg/module/{id}`,
                `get:${Api.path.knowledge}/msg/module/users/{id}`,
                `get:${Api.path.knowledge}/user/org/auth`,
                `get:${Api.path.knowledge}/user/user/auth`
              ]
            },
            component: () => import('@/webapp/message/orga-list.vue')
          }
        ]
      },
      {
        path: '/templates',
        meta: {
          id: 'b2981232-ba9a-4989-8261-6caa65ffc3ab',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'templates',
          title: '消息模板',
          apis: [
            `get:${Api.path.knowledge}/msg/module/page/list`,
            `get:${Api.path.knowledge}/msg/module/{id}`,
            `get:${Api.path.knowledge}/msg/module/users/{id}`
          ]
        },
        component: () => import('@/webapp/message/template-list.vue')
      }
    ]
  }
]
