
export const SERVICE_ITEM = [{ value: 1, label: '文书代写' }, { value: 2, label: '文书审核' }, { value: 5, label: '标准合同数' }, { value: 6, label: '企业成员数' }, { value: 4, label: '合同智能审核' }, { value: 8, label: '合同对比' }, { value: 9, label: '企业风险体检' }, { value: 12, label: '合同履约' }, { value: 13, label: '法律咨询' }, { value: 14, label: '电子签署' }, { value: 15, label: '数据保全' }, { value: 16, label: '人工咨询' }, { value: 17, label: 'AI问答' }, { value: 18, label: '文档分析' }, { value: 19, label: '文档咨询' }, { value: 20, label: '存储容量' }, { value: 21, label: '语音识别' }, { value: 22, label: '上门服务' }, { value: 23, label: '法律文书' }, { value: 24, label: '定制法律培训' }, { value: 25, label: '律师服务' }]

/**
 * 法律效力级别
 */
export const lawLevels = [
  {
    value: 1,
    name: 'law',
    label: '宪法'
  },
  {
    label: '法律',
    name: 'law',
    children: [
      { value: 2, label: '法律' },
      { value: 6, label: '法律解释' }
    ]
  },
  {
    label: '行政法规',
    name: 'administration',
    children: [
      { value: 3, label: '行政法规' },
      { value: 12, label: '行政法规解释' }
    ]
  },
  {
    value: 13,
    name: 'monitor',
    label: '监察法规'
  },
  {
    label: '地方性法规则',
    name: 'place',
    children: [
      {
        value: 9,
        label: '省级地方性法则'
      },
      {
        value: 5,
        label: '设区的地方性法规'
      },
      {
        value: 10,
        label: '经济特区法规'
      },
      {
        value: 11,
        label: '自治条例和单行条例'
      }
    ]
  },
  {
    label: '部门规章',
    name: 'dep',
    children: [
      { value: 14, label: '部门规章' },
      { value: 15, label: '部门规范性文件' },
      { value: 16, label: '部门工作文件' },
      { value: 17, label: '行政许可批复' }
    ]
  },
  {
    label: '司法解释',
    name: 'judicature',
    children: [
      { value: 7, label: '司法解释' },
      { value: 18, label: '司法解释性质文件' }
    ]
  },
  {
    value: 19,
    name: 'gov',
    label: '地方政府规章'
  },
  {
    value: 24,
    name: 'other',
    label: '其它'
  }
]

export const UNIT = ['次', '份', '个', 'G', '分钟', '页', '人', '篇']

export const VISIT_TYPE = ['其它', '陪同谈判', '参与重大会议', '处理纠纷', '重大合同拟定']

export const CONSULTATION_TYPE = ['不限', '劳动纠纷', '银行卡冻结']

// 企业咨询方式
export const ENT_CONSULT_TYPE = ['微信文字咨询', '微信语音咨询', '电话咨询', '线下咨询']

export const ENT_QUESTION_TYPE = ['合同管理', '管理制度', '公司设立与变更', '劳动人事', '知识产权', '财税管理', '账款管理', '投资与融资', '诉讼仲裁', '其他']

export const EVALUATE_OBJECT = ['客户', '法务经理', '法务助理', '律师', '律师助理']

export const MESSAGE_TYPE = ['其它消息', '系统通知', '服务通知', '产品消息']

export const MESSAGE_SEND_TYPE = ['及时推送', '定时推送', '系统自动推送']

export const MESSAGE_TYPE_TIP = ['自定义内容通知', '实名认证（系统自动推送），运行维护通知', '服务提醒、购买提醒、操作提醒等（系统自动推送），年度报告', '营销内容、服务调查、意见收集等']

export const MESSAGE_RANGE = ['全部用户', '自定义用户']
