export default {
  path: 'greyList',
  name: 'greyList',
  meta: {
    id: '',
    name: 'greyList',
    title: '灰名单Api列表',
    apis: [
      'get:/operation/v1/user/current',
      'post:/operation/v1/user/current',
      'post:/operation/v1/user/current/password',
      'post:/operation/v1/user/current/phone',
      'get:/operation/v1/gateway/user/{userId}/operation',
      'post:/operation/v1/common/file',
      'post:/operation/v1/common/code/{biz}',
      'put:/operation/v1/common/code/{biz}',
      'get:/operation/v1/gateway/user/{userId}/orga/{orgaId}/operation',
      'post:/operation/v1/common/code/cos',
      'get:/operation/v1/common/code/cos',
      'post:/operation/v1/common/validate/user/account',
      'post:/knowledge/v1/file/batch',
      'post:/knowledge/v1/file/pdf2html',
      'post:/knowledge/v1/file/down',
      'post:/knowledge/v1/law/agreement/flow/list/sensitive/words',
      'post:/knowledge/v1/law/agreement/flow/list/key/words',
      'get:/knowledge/v1/law/agreement/flow/list/risk',
      'get:/knowledge/v1/law/agreement/flow/list/log',
      'get:/knowledge/v1/law/clause/page/search',
      'get:/knowledge/v1/law/agreement/page/list',
      'get:/knowledge/v1/law/agreement/{id}',
      'get:/operation/v1/orga/0/member',
      'get:/operation/v1/orga/0/role',
      'post:/operation/v1/common/format/minhtml',
      'get:/knowledge/v1/law/issued/list',
      'get:/knowledge/v1/law/document/page/list',
      'get:/knowledge/v1/index/data/content',
      'get:/knowledge/v1/index/data/agreement',
      'get:/knowledge/v1/index/data/category/rinking',
      'get:/knowledge/v1/index/data/count',
      'get:/knowledge/v1/index/data/visit',
      'post:/office-convert/v1/to/pdf',
      'post:/office-convert/v1/to/html',
      'get:/knowledge/v1/law/agreement/service/page/list',
      'post:/operation/v1/common/validate/wechat/userid',
      'get:/knowledge/v1/law/search/list',
      'get:/knowledge/v1/index/data/category/document/rinking',
      'get:/knowledge/v1/index/data/service',
      'get:/knowledge/v1/index/data/login',
      'get:/knowledge/v1/index/data/user',
      'get:/knowledge/v1/index/data/service/count',
      'get:/knowledge/v1/index/data/city/rinking',
      'post:/operation/v1/office/new',
      'post:/operation/v1/office/config',
      'post:/operation/v1/office/command',
      'get:/knowledge/v1/user/count/status',
      'get:/knowledge/v1/index/data/risk',
      'post:/wechat/v1/info/web/qw/{agentid}/config',
      'post:/wechat/v1/info/web/qw/{agentid}/agent/config',
      'put:/operation/v1/common/format/video/task',
      'get:/knowledge/v1/law/rule/list',
      'get:/knowledge/v1/index/data/onsite',
      'get:/operation/v1/orga/auth/orga/missive/count',
      'put:/knowledge/v1/category/sort/{id}',
      'get:/wechat/v1/info/mini/{appId}/scheme',
      'get:/operation/v1/lawyer/auth/count'
    ]
  }
}
