import Vue from 'vue'
import ElementUI from 'element-ui'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import filters from '@/utils/filter'
import ftLoading from '@/components/ft-loading'
import '@/styles/index.less'
import '@/assets/css/theme/index.css'
import './assets/css/common.css'
import './assets/css/common.less'
import {
  resubmitInterceptor,
  permission,
  errorInterceptor
} from '@ft/common-library'
import AuthUtils from '@/utils/usreinfo'
Object.keys(filters).forEach((row) => {
  Vue.filter(row, filters[row])
})

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.use(permission)
Vue.use(ftLoading)

const domainString = location.hostname.split('.')
const domain = [domainString[domainString.length - 2], domainString[domainString.length - 1]].join('.')
const auth = new AuthUtils(domain, 'platform-tk')
window._auth = auth
Vue.prototype._auth = auth
Vue.prototype.$loadingClose = null
// 请求时添加登录信息
axios.interceptors.request.use(function (config) {
  config.headers.token = auth.getToken()
  config.headers.orgaId = 0
  // config.headers.orgId = 0
  // config.headers.orgType = 2
  return config
}, function (error) {
  return Promise.reject(error)
})

const ieIe = !!window.ActiveXObject || 'ActiveXObject' in window
const IE11RouterFix = {
  methods: {
    hashChangeHandler () { this.$router.push(window.location.hash.substring(1, window.location.hash.length)) }
  },
  mounted () { window.addEventListener('hashchange', this.hashChangeHandler) },
  destroyed () { window.removeEventListener('hashchange', this.hashChangeHandler) }
}

// ie10,ie11
const mixins = ieIe ? [IE11RouterFix] : []

// 处理 area 数据
const removeNullChildren = (list) => {
  list.forEach(row => {
    if (!row.children || row.children.length === 0) {
      delete row.children
    } else {
      removeNullChildren(row.children)
    }
  })
}
removeNullChildren(window.dict.area)
removeNullChildren(window.dict.standardIndustry)

if (auth.isLogin()) {
  axios.get('/operation/v1/user/current').then(async ({ data }) => {
    if (!data.result) {
      return
    }
    auth.userInfo = data.result
  }).catch(() => {
  })
  // 获取用户权限
  const xhr = new XMLHttpRequest()
  xhr.open('get', `/operation/v1/gateway/user/${auth.getUserId()}/orga/0/operation`, false)
  xhr.setRequestHeader('token', auth.getToken())
  xhr.send()
  if (xhr.status === 200) {
    const res = JSON.parse(xhr.responseText)
    permission.setAuto({ operations: res.result.map(opKid => ({ opKid })) })
  }
}

window.global = new Vue({
  mixins: mixins,
  router,
  filters,
  render: h => h(App)
}).$mount('#app')

resubmitInterceptor(axios)
errorInterceptor(axios, {
  vm: window.global,
  needProcess: (error) => {
    // if (error?.response?.data?.code === 400004) {
    //   router.push('/login')
    //   return true
    // }
    // if (error?.response?.data?.code === 404001) {
    //   window.global.$message.error('无法调用接口，请添加接口权限: ' + error?.request?.responseURL)
    //   return true
    // }
    // if (error?.response?.data?.code === 401002) {
    //   auth.clearToken()
    //   router.push('/login')
    //   window.global.$alert('登录状态已过期，请确认', {
    //     confirmButtonText: '确定',
    //     title: '登录失效',
    //     type: 'warning',
    //     callback: action => {
    //     }
    //   })
    //   return true
    // }
    // if (error?.response?.data?.code === 401003) {
    //   auth.clearToken()
    //   router.push('/login')
    //   window.global.$alert('当前用户已在其它地方登录，请确认', {
    //     confirmButtonText: '确定',
    //     title: '登录失效',
    //     type: 'warning',
    //     callback: action => {
    //     }
    //   })
    //   // window.global.$message.error(error?.response?.data?.message)
    //   return true
    // }
    if (error?.response?.data?.status >= 400) {
      window.global.$message.error(`${error?.response?.data?.message}`)
      return true
    }
    return false
  },
  loginUrl: () => { return '#/login' }
})

axios.interceptors.response.use(res => {
  if (res?.data?.code > 200 && res?.data?.message) {
    window.global.$message.error('' + res?.data?.message)
    return Promise.reject(new Error('' + res?.data?.message))
  } else {
    return Promise.resolve(res)
  }
})

auth.onUserIdChange = async function (userId) {
  if (!userId) {
    auth.clearToken()
    auth.userInfo = null
    window.sessionStorage.clear()
    if (!window.location.hash.startsWith('#/login')) {
      router.replace('/login')
    }
  } else {
    // 更新权限
    await axios.get('/api/update_current_permission')
    window.location.href = '/'
  }
}

// 更新权限
if (auth.isLogin()) {
  axios.get('/api/update_current_permission')
}
