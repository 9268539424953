const orderPerson = () => import('@/webapp/service/order/person.vue')
const orderOrga = () => import('@/webapp/service/order/orga.vue')
const orderLaw = () => import('@/webapp/service/order/law.vue')
const grantPerson = () => import('@/webapp/service/grant/person.vue')
const grantOrga = () => import('@/webapp/service/grant/orga.vue')
const grantLaw = () => import('@/webapp/service/grant/law.vue')

const Api = require('../apis/path')
export default [
  {
    path: '/order-center',
    meta: {
      id: '64355c05-92fe-4372-b117-678422b1762d',
      icon: '',
      name: 'order-center',
      title: '订单中心',
      apis: [
      ]
    },
    children: [
      {
        path: '/order',
        meta: {
          id: 'd23e32ec-f7c8-44cd-a220-7aaf73fe5d69',
          icon: 'iconfont icon-dingdanguanliicon',
          iconColor: '#507bfd',
          name: 'order',
          title: '订单管理'
        },
        children: [
          {
            path: '/person',
            meta: {
              id: 'fe4e08ae-fb52-439a-a22b-bb81045dcffd',
              icon: '',
              name: 'person',
              title: '个人订单',
              apis: [
                    `get:${Api.path.knowledge}/mall/order/page/list/{orderType}`,
                    `get:${Api.path.knowledge}/mall/order/{id}`,
                    `put:${Api.path.knowledge}/mall/order/refund/{id}`,
                    `put:${Api.path.knowledge}/mall/order/confirm/{id}`,
                    `post:${Api.path.knowledge}/mall/order/excel/{orderType}`
              ],
              actions: [
                {
                  id: '8c754cb7-659a-4eef-a3c2-27527d9a42c6',
                  name: 'refund',
                  title: '退款',
                  apis: []
                }
              ]
            },
            component: orderPerson
          },
          {
            path: '/orga',
            meta: {
              id: '510861c5-6eb8-414c-bb5f-680e5ce12068',
              icon: '',
              name: 'orga',
              title: '企业订单',
              apis: [
                    `get:${Api.path.knowledge}/mall/order/page/list/{orderType}`,
                    `get:${Api.path.knowledge}/mall/order/{id}`,
                    `put:${Api.path.knowledge}/mall/order/refund/{id}`,
                    `put:${Api.path.knowledge}/mall/order/confirm/{id}`,
                    `post:${Api.path.knowledge}/mall/order/excel/{orderType}`
              ],
              actions: [
                {
                  id: '4c96e17f-e5da-4d42-80c0-f09af0ddd125',
                  name: 'refund',
                  title: '退款',
                  apis: []
                }
              ]
            },
            component: orderOrga
          },
          {
            path: '/law',
            meta: {
              id: '21e01cfc-8c81-4cae-a4bb-2c231036f876',
              icon: '',
              name: 'law',
              title: '律师订单',
              apis: [
                `get:${Api.path.knowledge}/mall/order/page/list/{orderType}`,
                `get:${Api.path.knowledge}/mall/order/{id}`,
                `put:${Api.path.knowledge}/mall/order/refund/{id}`,
                `put:${Api.path.knowledge}/mall/order/confirm/{id}`,
                `post:${Api.path.knowledge}/mall/order/excel/{orderType}`
              ],
              actions: [
                {
                  id: '2bbd006f-708b-4a56-970a-c9bc4a8dc981',
                  name: 'refund',
                  title: '退款',
                  apis: []
                }
              ]
            },
            component: orderLaw
          },
          {
            path: '/template-sell',
            meta: {
              id: '0948da15-afd5-4a26-8ada-fdaf8198a750',
              icon: '',
              name: 'template-sell',
              title: '合同销量统计',
              apis: [
                `get:${Api.path.knowledge}/law/document/page/count/sells`
              ]
            },
            component: () => import('@/webapp/service/order/template-sell.vue')
          }
        ]
      },
      {
        path: '/service-grant',
        meta: {
          id: '7ea0e7ad-3aa8-4d07-b6c7-3eb02c067302',
          icon: 'iconfont icon-dingdanguanliicon',
          iconColor: '#507bfd',
          name: 'service-grant',
          title: '服务开通管理'
        },
        children: [
          {
            path: '/person',
            meta: {
              id: '1720598c-fc0b-485c-a814-e05c1c3df2b3',
              icon: '',
              name: 'person',
              title: '个人服务开通',
              apis: [
                    `get:${Api.path.user}/user`,
                    `get:${Api.path.knowledge}/mall/product/page/list`,
                    `get:${Api.path.knowledge}/mall/order/service/page/list/{type}`,
                    `get:${Api.path.knowledge}/mall/order/service/details`,
                    `post:${Api.path.knowledge}/mall/order/service/open`,
                    `get:${Api.path.knowledge}/mall/product/info/{id}`
              ]
            },
            component: grantPerson
          },
          {
            path: '/orga',
            meta: {
              id: '940174b8-4754-4304-a771-d422292a06fe',
              icon: '',
              name: 'orga',
              title: '企业服务开通',
              apis: [
                    `get:${Api.path.user}/orga`,
                    `get:${Api.path.user}/orga/{id}`,
                    `get:${Api.path.knowledge}/mall/product/page/list`,
                    `get:${Api.path.knowledge}/mall/order/service/page/list/{type}`,
                    `get:${Api.path.knowledge}/mall/order/service/details`,
                    `post:${Api.path.knowledge}/mall/order/service/open`,
                    `get:${Api.path.knowledge}/mall/product/info/{id}`
              ]
            },
            component: grantOrga
          },
          {
            path: '/law',
            meta: {
              id: '737fdbd3-a01c-43e6-b4d2-4bf97c74570b',
              icon: '',
              name: 'law',
              title: '律师服务开通',
              apis: [
                `get:${Api.path.user}/user`,
                `get:${Api.path.knowledge}/mall/product/page/list`,
                `get:${Api.path.knowledge}/mall/order/service/page/list/{type}`,
                `get:${Api.path.knowledge}/mall/order/service/details`,
                `post:${Api.path.knowledge}/mall/order/service/open`,
                `get:${Api.path.knowledge}/mall/product/info/{id}`,
                `get:${Api.path.knowledge}/mall/product/client/assistance`

              ]
            },
            component: grantLaw
          }
        ]
      }
    ]
  }
]
