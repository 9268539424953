export default {
  path: 'whiteList',
  name: 'whiteList',
  meta: {
    id: '',
    name: 'greyList',
    title: '白名单Api列表',
    apis: [
      'get:/operation/v1/common/code/image',
      'get:/operation/v1/upload/{filename}',
      'post:/knowledge/v1/pay/notify', // 微信支付回调接口
      'post:/knowledge/v1/pay/alipay/notify', // 支付宝支付回调接口
      'post:/wechat/v1/info/users/qw/{agentid}/user',
      'post:/operation/v1/common/register/qw/bind',
      'post:/operation/v1/common/format/video/task',
      'post:/sms/v1/getIdentifyingCode',
      'post:/sms/v1/validCode',
      'post:/knowledge/v1/user/onsite/service/complete',
      'put:/knowledge/v1/user/onsite/service/submit/{id}',
      'post:/knowledge/v1/mp'
    ]
  }
}
