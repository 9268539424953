<template>
  <el-popover
    placement="left"
    width="400"
    trigger="click"
    >
    <div class="ft-frame-list">
        <div class="search">
            <span>法规检索：</span>
            <el-input v-model="searchData.keyword" placeholder="可按法规名称/代码检索" style="width: 250px" @input="search"></el-input>
        </div>
        <div class="list">
            <div class="item" v-for="item,index in list" :key="index">
                <div class="title">{{ item.title }}</div>
                <div class="code">
                    <span class="code-title">分类：</span>
                    <span class="code">{{ item.category }}</span>
                </div>
                <div class="code">
                    <span class="code-title">代码：</span>
                    <span class="code">{{ item.code }}</span>
                </div>
            </div>
        </div>
    </div>
    <div slot="reference" class="ft-frame-tools">
        <div class="icon center">
            <i class="iconfont icon-sousuoleimu"></i>
        </div>
        <div class="title center">
            法规代码
        </div>
    </div>
  </el-popover>
</template>

<script>
import { debounce } from '@ft/common-library'
import axios from 'axios'
export default {
  data () {
    return {
      searchData: {
        keyword: ''
      },
      list: []
    }
  },
  methods: {
    search: debounce(async function () {
      if (this.searchData.keyword) {
        const res = await axios.get('/knowledge/v1/law/search/list', { params: { keyword: this.searchData.keyword || null } })
        this.list = res.data.result
      } else {
        this.list = []
      }
    }, 500)
  }
}
</script>

<style scoped lang="less">
.ft-frame-tools {
    z-index: 1000;
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 55px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    cursor: pointer;
    .icon {
        margin-top: 5px;
        i {
            font-size: 24px;
        }

    }
    .title {
        font-size: 12px;
        margin-top: 0px;
    }
    .center {
        text-align: center;

    }
}
.ft-frame-list{
    padding: 18px;
    height: 370px;
    display: flex;
    flex-direction: column;
    .search {
        flex: 0 0 20px;
        font-size: 16px;
        color: #333333;
    }
    .list {
        flex:  1 1 auto;
        height: 0;
        overflow-y: auto;
        margin-top: 30px;
        .item {
            &+.item {
                margin-top: 24px;
            }
            .title {
                font-size: 14px;
                color: #333333;
            }
            .category {
                margin-top: 9px;
                font-size: 14px;
                color: #333333;
            }
            .code {
                margin-top: 9px;
                font-size: 14px;
                color: #333333;
                .code-title {
                    color: #999;
                }
            }

        }
    }
}
</style>
