const single = () => import('@/webapp/market/commodity/single.vue')
const multiple = () => import('@/webapp/market/commodity/multiple.vue')
const packages = () => import('@/webapp/market/commodity/package.vue')
const services = () => import('@/webapp/market/commodity/services.vue')
const redeemCode = () => import('@/webapp/market/manage/redeem-code.vue')
const brokerage = () => import('@/webapp/market/manage/brokerage.vue')
const questions = () => import('@/webapp/market/examination/questions.vue')
const questionnaire = () => import('@/webapp/market/examination/questionnaire.vue')
// const messagePerson = () => import('@/webapp/market/message/person.vue')
// const messageOrga = () => import('@/webapp/market/message/orga.vue')

const Api = require('../apis/path')
export default [
  {
    path: '/market',
    meta: {
      id: 'a181d712-fabe-46db-bc10-b75b9dee5f51',
      icon: '',
      name: 'market',
      title: '营销中心',
      apis: [
      ]
    },
    children: [
      {
        path: '/commodity-manage',
        meta: {
          id: 'cc4fa6a8-3403-4fdf-89fb-32cbe6534f37',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'commodity-manage',
          title: '商品管理'
        },
        children: [
          {
            path: '/single',
            meta: {
              id: '67390886-95af-4290-bdda-274614d029db',
              icon: '',
              iconColor: '',
              name: 'single',
              title: '单商品管理',
              apis: [
                `get:${Api.path.knowledge}/mall/product/page/list`,
                `get:${Api.path.knowledge}/mall/product/info/{id}`,
                `post:${Api.path.knowledge}/mall/product`,
                `put:${Api.path.knowledge}/mall/product/edit/{id}/status/{status}`,
                `put:${Api.path.knowledge}/mall/product/{id}`,
                `delete:${Api.path.knowledge}/mall/product/{id}`
              ],
              actions: [
                {
                  id: 'd8056eb7-eb60-47c5-b9d4-88f9b633f732',
                  name: 'add',
                  title: '创建商品',
                  apis: []
                },
                {
                  id: '86b17258-ad28-458a-9007-a0327dd5cfa9',
                  name: 'edit',
                  title: '编辑商品',
                  apis: []
                },
                {
                  id: '143e8350-1df7-4d7d-9fae-9430ed3a0025',
                  name: 'del',
                  title: '删除商品',
                  apis: []
                },
                {
                  id: '222c9fcc-ffc1-479a-ae5d-aa37d10e5195',
                  name: 'updown',
                  title: '上下架商品',
                  apis: []
                }
              ]
            },
            component: single
          },
          {
            path: '/multiple',
            meta: {
              id: 'b70a6f05-96d1-4b49-b106-50942c5c8c32',
              icon: '',
              iconColor: '',
              name: 'multiple',
              title: '组合商品管理',
              apis: [
                `get:${Api.path.knowledge}/mall/product/page/list`,
                `get:${Api.path.knowledge}/mall/product/compose/{id}`,
                `post:${Api.path.knowledge}/mall/product/adds`,
                `put:${Api.path.knowledge}/mall/product/edit/{id}/status/{status}`,
                `delete:${Api.path.knowledge}/mall/product/{id}`,
                `put:${Api.path.knowledge}/mall/product/edits/{id}`
              ],
              actions: [
                {
                  id: '3f9c9940-b4c7-40b0-929d-75224f34e731',
                  name: 'add',
                  title: '创建商品',
                  apis: []
                },
                {
                  id: '90127fc6-bff7-4520-8482-4426ee42b30c',
                  name: 'edit',
                  title: '编辑商品',
                  apis: []
                },
                {
                  id: '510237b8-766b-4ce4-8a7d-32607b8eb1be',
                  name: 'del',
                  title: '删除商品',
                  apis: []
                },
                {
                  id: 'dca7ba98-2b1c-4220-930c-63058839c6cb',
                  name: 'updown',
                  title: '上下架商品',
                  apis: []
                }
              ]
            },
            component: multiple
          },
          {
            path: '/packages',
            meta: {
              id: '913c1483-7918-4671-b5c7-67eebdf5a75f',
              icon: '',
              iconColor: '',
              name: 'packages',
              title: '合同包管理',
              apis: [
                `get:${Api.path.knowledge}/mall/product/page/list`,
                `get:${Api.path.knowledge}/mall/document/page/list`,
                `get:${Api.path.knowledge}/mall/product/documents/{id}`,
                `put:${Api.path.knowledge}/mall/product/documents/{id}`,
                `post:${Api.path.knowledge}/mall/product/documents`,
                `get:${Api.path.knowledge}/law/document/page/list`,
                `put:${Api.path.knowledge}/mall/product/top/{id}`
              ]
            },
            component: packages
          },
          {
            path: '/service',
            meta: {
              id: '157d0e1f-5396-4ea4-9885-f12101298c40',
              icon: '',
              iconColor: '',
              name: 'service',
              title: '产品服务',
              apis: [
                `get:${Api.path.knowledge}/mall/product/page/list`,
                `get:${Api.path.knowledge}/mall/product/platform/laws`,
                `delete:${Api.path.knowledge}/mall/product/client/laws/{id}`,
                `put:${Api.path.knowledge}/mall/product/client/laws`,
                `put:${Api.path.knowledge}/mall/product/laws/sort/{id}`,
                `get:${Api.path.knowledge}/mall/product/client/assistance`,
                `delete:${Api.path.knowledge}/mall/product/client/assistance/{id}`,
                `put:${Api.path.knowledge}/mall/product/client/assistance`,
                `put:${Api.path.knowledge}/mall/product/assistance/sort/{id}`,
                `put:${Api.path.knowledge}/mall/product/edit/{id}/scope/{scope}`
              ]
            },
            component: services
          }
        ]
      },
      {
        path: '/risk-examination',
        meta: {
          id: '9e877da3-ca18-4ed0-b26e-9e1768ca950e',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'risk-examination',
          title: '风险体检'
        },
        children: [
          {
            path: '/questions',
            meta: {
              id: 'ff3bab39-4483-478f-a247-c97db4370389',
              icon: '',
              iconColor: '',
              name: 'questions',
              title: '题目管理',
              apis: [
                `get:${Api.path.knowledge}/questionnaire/subject/page/list`,
                `get:${Api.path.knowledge}/questionnaire/subject/{id}`,
                `post:${Api.path.knowledge}/questionnaire/subject`,
                `put:${Api.path.knowledge}/questionnaire/subject/{id}`,
                `delete:${Api.path.knowledge}/questionnaire/subject/{id}`,
                `get:${Api.path.knowledge}/questionnaire/subject/risk/{id}`,
                `put:${Api.path.knowledge}/questionnaire/subject/risk/{id}`,
                `post:${Api.path.knowledge}/questionnaire/batch/{categoryId}`
              ]
            },
            component: questions
          },
          {
            path: '/questionnaire',
            meta: {
              id: '0444fcde-e302-4607-9b64-19b859535b8d',
              icon: '',
              iconColor: '',
              name: 'questionnaire',
              title: '问卷管理',
              apis: [
                `get:${Api.path.knowledge}/questionnaire/page/list`,
                `get:${Api.path.knowledge}/questionnaire/{id}`,
                `put:${Api.path.knowledge}/questionnaire/{id}`,
                `delete:${Api.path.knowledge}/questionnaire/{id}`,
                `post:${Api.path.knowledge}/questionnaire`,
                `post:${Api.path.knowledge}/questionnaire/generator/{id}`,
                `get:${Api.path.knowledge}/questionnaire/subject/page/list`,
                `get:${Api.path.knowledge}/questionnaire/physical/examination/scenario/page`,
                `get:${Api.path.knowledge}/questionnaire/physical/examination/scenario/all`,
                `post:${Api.path.knowledge}/questionnaire/physical/examination/scenario/join`,
                `post:${Api.path.knowledge}/questionnaire/physical/examination/scenario/edit/{id}`,
                `delete:${Api.path.knowledge}/questionnaire/physical/examination/scenario/remove/{id}`,
                `get:${Api.path.knowledge}/questionnaire/preview/{id}`,
                `get:${Api.path.knowledge}/questionnaire/category/{id}`,
                `put:${Api.path.knowledge}/questionnaire/category`,
                `get:${Api.path.knowledge}/questionnaire/subject/page/count`,
                `put:${Api.path.knowledge}/questionnaire/batch/cancel/{id}`,
                `get:${Api.path.knowledge}/questionnaire/online/page`
              ]
            },
            component: questionnaire
          }
        ]
      },
      {
        path: '/market-manage',
        meta: {
          id: '22d7df67-f129-415e-b062-9f48f35f8810',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'market-manage',
          title: '营销管理'
        },
        children: [
          {
            path: '/redeem-code',
            meta: {
              id: 'f16ea58e-c587-4859-b282-3a94c35ba683',
              icon: '',
              iconColor: '',
              name: 'redeem-code',
              title: '兑换码',
              apis: [
                `get:${Api.path.knowledge}/mall/exchange/certificate/page/list`, // 获取优惠卷列表
                `post:${Api.path.knowledge}/mall/exchange/certificate`, // 添加优惠卷
                `get:${Api.path.knowledge}/mall/exchange/certificate/{id}`, // 获取优惠卷明细
                `delete:${Api.path.knowledge}/mall/exchange/certificate/{id}`, // 删除优惠卷
                `post:${Api.path.knowledge}/mall/exchange/certificate/generator/{id}`, // 生成优惠卷
                `get:${Api.path.knowledge}/mall/product/page/list`, // 商品列表
                `get:${Api.path.knowledge}/mall/exchange/certificate/use/page/list/{exchangeId}`, // 码库分页列表
                `put:${Api.path.knowledge}/mall/exchange/certificate/lose/{id}`, // 兑换码组失效
                `put:${Api.path.knowledge}/mall/exchange/certificate/lose/use/{id}`, // 单个兑换码失效
                `get:${Api.path.knowledge}/mall/exchange/certificate/use/page/data/{exchangeId}`, // 数据兑换码分页列表
                `get:${Api.path.knowledge}/mall/exchange/certificate/use/page/data/count/{id}`, // 统计数据
                `get:${Api.path.knowledge}/mall/product/client/assistance/singleton`,
                `get:${Api.path.knowledge}/mall/product/client/assistance/compose`
              ]
            },
            component: redeemCode
          },
          {
            path: '/brokerage',
            meta: {
              id: 'bf5e9484-9316-4c86-95de-b3858eca3d37',
              icon: '',
              iconColor: '',
              name: 'brokerage',
              title: '分佣记录',
              apis: [
                `get:${Api.path.knowledge}/income/count`,
                `get:${Api.path.knowledge}/income/info/{id}`,
                `get:${Api.path.knowledge}/income/page`
              ]
            },
            component: brokerage
          }
        ]
      }
      // {
      //   path: '/message',
      //   meta: {
      //     id: '83637a21-0913-4904-b270-d28191e758b7',
      //     icon: 'iconfont icon-menleiguanliicon',
      //     iconColor: '#4c7cff',
      //     name: 'message',
      //     title: '留言管理'
      //   },
      //   children: [
      //     {
      //       path: '/person',
      //       meta: {
      //         id: '9d1a9055-0f52-451a-8616-e8e9fcfec027',
      //         name: 'person',
      //         title: '个人用户留言'
      //       },
      //       component: messagePerson
      //     },
      //     {
      //       path: '/orga',
      //       meta: {
      //         id: '2aed8991-55bf-4337-8ce8-aac141d610ed',
      //         name: 'orga',
      //         title: '企业用户留言'
      //       },
      //       component: messageOrga
      //     }
      //   ]

      // }
    ]
  }
]
