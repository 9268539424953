import Vue from 'vue'
import loadingVue from './loading'

function trim (string) {
  return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')
}
function hasClass (el, cls) {
  if (!el || !cls) return false
  if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.')
  if (el.classList) {
    return el.classList.contains(cls)
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
  }
}
function afterLeave (instance, callback, speed = 300, once = false) {
  if (!instance || !callback) throw new Error('instance & callback is required')
  let called = false
  const afterLeaveCallback = function () {
    if (called) return
    called = true
    if (callback) {
      callback.apply(null, arguments)
    }
  }
  if (once) {
    instance.$once('after-leave', afterLeaveCallback)
  } else {
    instance.$on('after-leave', afterLeaveCallback)
  }
  setTimeout(() => {
    afterLeaveCallback()
  }, speed + 100)
}
function removeClass (el, cls) {
  if (!el || !cls) return
  const classes = cls.split(' ')
  let curClass = ' ' + el.className + ' '

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i]
    if (!clsName) continue

    if (el.classList) {
      el.classList.remove(clsName)
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(' ' + clsName + ' ', ' ')
    }
  }
  if (!el.classList) {
    el.setAttribute('class', trim(curClass))
  }
}
function merge (target) {
  for (let i = 1, j = arguments.length; i < j; i++) {
    const source = arguments[i] || {}
    for (const prop in source) {
      if (source.hasOwnProperty(prop)) {
        const value = source[prop]
        if (value !== undefined) {
          target[prop] = value
        }
      }
    }
  }

  return target
}
function addClass (el, cls) {
  if (!el) return
  let curClass = el.className
  const classes = (cls || '').split(' ')

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i]
    if (!clsName) continue

    if (el.classList) {
      el.classList.add(clsName)
    } else if (!hasClass(el, clsName)) {
      curClass += ' ' + clsName
    }
  }
  if (!el.classList) {
    el.setAttribute('class', curClass)
  }
}

const defaults = {
  target: null, // 默认插入节点
  text: null, // 文本
  fullscreen: true, // 全屏
  body: false, // 插入body元素
  lock: false, // 锁屏
  customClass: '' // 自定义样式类
}
const LoadingConstructor = Vue.extend(loadingVue)
let fullscreenLoading

LoadingConstructor.prototype.close = function () {
  if (this.fullscreen) {
    fullscreenLoading = undefined
  }
  afterLeave(this, _ => {
    const target = this.fullscreen || this.body
      ? document.body
      : this.target
    removeClass(target, 'ft-loading-parent--relative')
    removeClass(target, 'ft-loading-parent--hidden')
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.$destroy()
  }, 300)
  this.visible = false
}

const Loading = (options = {}) => {
  options = merge({}, defaults, options)
  if (typeof options.target === 'string') {
    options.target = document.querySelector(options.target)
  }
  options.target = options.target || document.body
  if (options.target !== document.body) {
    options.fullscreen = false
  } else {
    options.body = true
  }
  if (options.fullscreen && fullscreenLoading) {
    return fullscreenLoading
  }

  const parent = options.body ? document.body : options.target
  const instance = new LoadingConstructor({
    el: document.createElement('div'),
    data: options
  })
  const parentPosition = window.getComputedStyle(parent).position
  if (parentPosition !== 'relative' && parentPosition !== 'absolute' && parentPosition !== 'fixed' && parentPosition !== 'sticky') {
    addClass(parent, 'ft-loading-parent--relative')
  }
  if (options.fullscreen && options.lock) {
    addClass(parent, 'ft-loading-parent--hidden')
  }
  parent.appendChild(instance.$el)
  Vue.nextTick(() => {
    instance.visible = true
  })
  if (options.fullscreen) {
    fullscreenLoading = instance
  }
  return instance
}

const LoadingPackage = {
  value: null,
  open: function (options = {}) {
    LoadingPackage.value = Loading(options)
  },
  close: function () {
    if (LoadingPackage.value) LoadingPackage.value?.close()
  }
}

export default {
  install (vm) {
    vm.prototype.$ft_loading = LoadingPackage
  }
}
