import storage from '@/utils/session_storage'
import { Auth } from '@ft/common-library'

class AuthUtils extends Auth {
  /**
   * @description 返回当前操作企业的账号信息
  */
  getCurrentUserInfo () {
    const servicePartnerId = window.sessionStorage.getItem(storage.SERVICE_PARTNER_ID)
    if (servicePartnerId) {
      return {
        accountType: '0',
        partnerId: Number(servicePartnerId),
        partnerType: '1',
        userName: this.userInfo.userName,
        userId: this.userInfo.userId
      }
    } else {
      return this.userInfo
    }
  }
}

export default AuthUtils
