<template>
  <div class="login">
    <div class="header">
      <div class="logo"></div>
      <div class="split"></div>
      <div class="title">法务平台运营管理系统</div>
    </div>
    <div class="content">
      <div class="midd">
        <div class="form" @keypress.enter="login">
          <div class="title">欢迎登录-法图法务平台管理系统</div>
          <el-form ref="ruleForm"  :hide-required-asterisk="true" :model="ruleForm" :rules="rules" class="login-form" >
            <el-form-item class="item" prop="phone">
              <template slot="label">
                <i class="iconfont icon-dengluzhanghao"/>
                <label class="label">登录帐号</label>
              </template>
              <el-input size="nomarl" v-model="ruleForm.phone" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item class="item" prop="password">
              <template slot="label">
                <i class="iconfont icon-denglumima"/>
                <label class="label">登录密码</label>
              </template>
              <el-input size="nomarl" type="password" v-model="ruleForm.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item class="item" prop="code">
              <template slot="label">
                <i class="iconfont icon-tuxingyanzhengma"/>
                <label class="label">图形验证码</label>
              </template>
              <div>
                <div class="code-content">
                  <el-input size="nomarl" class="el-ime-mode-disabled" v-model="ruleForm.code" placeholder="请输入图形验证码"></el-input>
                  <div ref="codeImgeEl" class="code" @click="refreshCode"></div>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="item" style="margin-top:20px">
              <el-button :loading="isLogining" @click="login" class="login-button">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="floor">
           Copyright  2022-2032 湖南数字法图科技有限公司 备案号: 湘ICP备2023009052号-1
        </div>
    </div>

  </div>
</template>
<script>
import Server from './apis'
import axios from 'axios'
import { permission } from '@ft/common-library'
export default {
  name: 'loginComponent',
  data () {
    return {
      isLogining: false,
      ruleForm: {
        phone: '',
        password: '',
        code: ''
      },
      text: '',
      rules: {
        phone: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  async mounted () {
    this.$apis = new Server(this.$axios, this.$message.warning)
    this.refreshCode()
  },
  methods: {
    async refreshCode () {
      const res = await this.$apis.getCode()
      this.$refs.codeImgeEl.innerHTML = res.data.result.svg
      const svg = this.$refs.codeImgeEl.querySelector('svg')
      svg.setAttribute('height', '32')
      this.text = res.data.result.text
    },
    async login () {
      if (this.isLogining) {
        return
      }
      try {
        this.isLogining = true
        const result = await this.$refs.ruleForm.validate()
        if (this.ruleForm.code !== this.text) {
          this.$message.error('验证码错误，请重新输入')
          return
        }
        if (result) {
          const res = await this.$apis.login(this.ruleForm.phone, this.ruleForm.password)
          // this._auth.setToken(res.data.access_token)
          const payload = { orgType: 2, orgId: 0 }
          const axiosInstance = axios.create({ headers: { token: res.data.access_token } })
          let headers = { ...payload }
          const res2 = await axiosInstance.get('/operation/v1/user/current')
          const data = res2.data.result.members.filter(row => row.orga_id === 0)[0]
          if (data) {
            headers = { ...headers, data_range: data.data_range || 1 }
            if (data.dep_id) {
              headers.dep_id = data.dep_id
            }
          } else {
            headers = { ...headers, data_range: 1 }
          }
          const res1 = await axiosInstance.post('/api/refresh_token', { payload: { orgType: 1, orgId: res2.data.result.userId, operation: payload }, headers: { orgType: 1, orgId: res2.data.result.userId, operation: headers } })
          this._auth.setToken(res1.data.access_token)

          // 获取用户信息
          const res3 = await axiosInstance.get('/operation/v1/user/current')
          this._auth.userInfo = res3.data.result

          // 获取用户权限
          const xhr = new XMLHttpRequest()
          xhr.open('get', `/operation/v1/gateway/user/${this._auth.getUserId()}/orga/0/operation`, false)
          xhr.setRequestHeader('token', this._auth.getToken())
          xhr.send()
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.responseText)
            permission.setAuto({ operations: res.result.map(opKid => ({ opKid })) })
          }
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.replace('/')
          }
        }
      } catch (e) {
        this.refreshCode()
      } finally {
        this.isLogining = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #4660d2;
  display: flex;
  flex-direction: column;
  .header {
    height: 60px;
    background-color: #fff;
    padding: 6px 20px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    .logo {
      background-image: url(@/assets/logo.png);
      background-size: 100%;
      height: 48px;
      width: 126px;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 18px;
      font-weight: 800;
    }
    .split {
      height: 36px;
      width: 1px;
      margin: 6px 20px;
      background-color: #ebebeb;
    }
  }
  .content {
    flex: 1 auto;
    display: flex;
    background-image: url(@/assets/images/login-bg.png);
    flex-direction: column;
    .form {
      width: 500px;
      height: 530px;
      background: #ffffff;
      border-radius: 8px;
      padding: 60px;
      box-sizing: border-box;
      .item {
        margin-bottom: 10px;
      }
      .login-button {
        width: 380px;
        height: 44px;
        background: #3350cb;
        border-radius: 4px;
        color: #fff;
        margin-top: 10px;

      }
      .title {
        font-size: 26px;
        font-weight: 600;
        text-align: center;
        color: rgba(42,42,44,0.98);
        line-height: 37px;
      }
      .code-content {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 100%;
        .code {
          margin-left: 10px;
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: center;
          /deep/ svg {
            width: 120px;
            height: 40px;
            background-color: #E6E7EF;
          }

        }
      }
      .login-form {
        margin-top: 40px;
        font-size: 14px;
        font-weight: 400;
        .icon {
          vertical-align: middle;
        }
        .label {
          margin-left: 8px;
        }
        .iconfont {
          color: #c7c8d0;
        }
      }
    }
    .midd {
      display: flex;
      flex: 1 auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .floor {
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      flex: 0 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
    }
  }
  .el-ime-mode-disabled {
    input {
      ime-mode: disabled;
    }
  }
}
</style>
