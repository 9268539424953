import dayjs from 'dayjs'
import { CONSULTATION_TYPE, ENT_CONSULT_TYPE, ENT_QUESTION_TYPE, MESSAGE_RANGE, MESSAGE_SEND_TYPE, MESSAGE_TYPE } from './constant'
export default {
  dateFormat: (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) return '-'
    return dayjs(date).format(format)
  },
  timeLinessText: (value) => {
    // 1-现已生效、2-现已失效、0-尚未生效
    switch (value) {
      case 1:
        return '现已生效'
      case 2:
        return '现已失效'
      case 0:
        return '尚未生效'
      case 3:
        return '已被修改'
      case 4:
        return '部分失效'
    }
    return '-'
  },
  hidePhone: (phone) => {
    if (!phone) {
      return ''
    } else {
      return phone.substr(0, 3) + '****' + phone.substr(7)
    }
  },
  lawLevelText: (value) => {
    //  1-宪法、2-律法、3-行政法规、4-省、自治区、直辖市地方性法规、5-设区的地方性法规
    switch (value) {
      case 1:
        return '宪法'
      case 2:
        return '法律'
      case 3:
        return '行政法规'
      case 4:
        return '省、自治区、直辖市地方性法规'
      case 5:
        return '设区的地方性法规'
    }
    return '-'
  },
  writType: (value) => {
    // 0-诉讼文书 1-非诉讼文书
    switch (value) {
      case 0:
        return '非诉讼文书'
      case 1:
        return '诉讼文书'
    }
    return '-'
  },
  clauseType: (value) => {
    // 0-单条款 1-条款组
    switch (value) {
      case 0:
        return '单条款'
      case 1:
        return '条款组'
    }
    return '-'
  },
  riskType: (value) => {
    // 0 合同类型 1 合同主体 2 合同标的 3 合同程序 4 交易结构 5 合同形式 6 合同条款
    const arrStr = ['合同类型', '合同主体', '合同标的', '合同程序', '交易结构', '合同形式', '合同条款']
    return arrStr[value] || '-'
  },
  sizeFormat: value => {
    if (value > 1024 * 1024) {
      return `${(value / (1024 * 1024)).toFixed(1)}M`
    } else {
      return `${(value / (1024)).toFixed(1)}KB`
    }
  },
  orderStatus: (value) => {
    return ['待支付', '已付款', '已取消', '超时关闭', '已退单', '退款中'][value] || '-'
  },
  payType: (value) => {
    return ['-', '微信支付', '支付宝支付', '对公转帐', '商品兑换码', '其它'][value] || '-'
  },
  orderType: (value) => {
    return ['线上下单', '手动录入'][value - 1] || '-'
  },
  money: (value, showSymble = true) => {
    return (showSymble ? '¥' : '') + ((value || 0) / 100).toThousand(2)
  },
  timeLong (val, isSecond = false, showSecond = false) {
    const dayTime = 60 * 24 * 60 * (isSecond ? 1 : 1000)
    const hourTime = 60 * 60 * (isSecond ? 1 : 1000)
    const minuteTime = 60 * (isSecond ? 1 : 1000)
    const secondTime = (isSecond ? 1 : 1000)
    const day = Math.floor(val / dayTime)
    const hour = Math.floor((val - day * dayTime) / hourTime)
    const minute = Math.floor((val - day * dayTime - hour * hourTime) / minuteTime)
    const second = Math.floor((val - day * dayTime - hour * hourTime - minute * minuteTime) / secondTime)
    let str = ''
    if (showSecond && second) {
      str = `${second}秒`
    }
    if (minute) {
      str = `${minute}分` + str
    }
    if (hour) {
      str = `${hour}时` + str
    }
    if (day) {
      str = `${day}天` + str
    }
    return str
  },
  secondFormat (second) {
    const sInt = parseInt(second)
    const hh = parseInt(sInt / 3600)
    let mm = parseInt((sInt % 3600) / 60)
    let ss = sInt % 60
    if (sInt < 60) {
      return sInt + ' 秒'
    } else if (sInt >= 60 && sInt < 3600) {
      if (ss === 0) {
        ss = ''
      } else {
        ss += ' 秒'
      }
      return mm + ' 分' + ss
    } else {
      if (mm === 0 && ss === 0) {
        mm = ''
        ss = ''
      } else {
        mm = (mm === 0 ? 0 : mm) + ' 分'
        ss = ss === 0 ? '' : ss + ' 秒'
      }
      return hh + ' 小时' + mm + ss
    }
  },
  serviceStatus (status) {
    return ['待受理', '受理中', '待确认', '已退回', '已完成'][status] || '-'
  },
  serviceType (type) {
    // 1-合同代写、2-合同审核、3-合同修改
    return ['-', '合同代写', '合同审核', '合同修改'][type] || '-'
  },
  writCategoryName (id, list) {
    const getName = function (id) {
      const row = list.find(row => row.id === id)
      if (row) {
        const name = getName(row.parentId)
        return (name ? name + '/' : '') + row.title
      } else {
        return ''
      }
    }
    return getName(id) || '-'
  },
  mainstayName (type) {
    return type === 1 ? '甲方' : '乙方'
  },
  redeemCodeStatus (status) {
    //  0-未开始、1-进行中、2-已结束、3-已失效
    return ['未开始', '进行中', '已结束', '已失效'][status] || '-'
  },
  redeemCodeType (type) {
    // 0 全部商品 1 部分商品
    return ['全部商品', '部分商品'][type] || '-'
  },
  redeemCodeUseStatus (status) {
    // 0-未兑换、1-已兑换、2-已作废、3-已过期
    return ['未兑换', '已兑换', '已作废', '已过期'][status] || '-'
  },
  orderServiceStatus (status) {
    return ['', '已生效', '已用完', '已过期'][status] || '-'
  },
  questionRiskLevel (value) {
    return ['无', '低风险', '中风险', '高风险', '超高风险'][value] || '-'
  },
  questionType (value) {
    return ['', '单选项', '多选项', '单选填空', '多选填空', '矩阵填空', '表格组合'][value] || '-'
  },
  transCoded (value) {
    return ['未转码', '已转码', '转码失败'][value]
  },
  examinationStatus (value) {
    return ['未推送', '已推送', '待评估', '已完成'][value] || '-'
  },
  satisfactionStatus (value) {
    return ['-', '满意', '一般', '不满意'][value] || '-'
  },
  courseDisplay (value) {
    return ['全部', '个人', '企业'][value] || '-'
  },
  orgaType (value) {
    return ['法人企业', '个体工商户', '其它组织'][value] || '-'
  },
  lawyerAuthType (value) {
    return ['执业律师', '实习律师'][value] || '-'
  },
  consultationType (val) {
    return CONSULTATION_TYPE[val] || '-'
  },
  visitStatus (val) {
    return ['待上门', '服务中', '待确认', '已完成', '已取消'][val] || '-'
  },
  entConsultType (val) {
    return ENT_CONSULT_TYPE[val] || '-'
  },
  entQuestionType (val) {
    return ENT_QUESTION_TYPE[val] || '-'
  },
  messageType (val) {
    return MESSAGE_TYPE[val] || '-'
  },
  messageSendType (val) {
    return MESSAGE_SEND_TYPE[val - 1] || '-'
  },
  messageRange (val) {
    return MESSAGE_RANGE[val] || '-'
  }

}
