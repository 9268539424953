import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '@/components/ft-frame.vue'
import LoginView from '@/webapp/login/index.vue'
import routerMain from './router.main'
import axios from 'axios'
import { MessageBox } from 'element-ui'

const vuePush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return vuePush.call(this, location).catch(err => err)
}

const { routerList } = routerMain

const flattenTree = (data, prefix) => {
  const list = data.reduce((pre, item) => [...pre, item, ...flattenTree(item.children || [], item.path)], [])
  list.forEach(row => {
    row.aPath = prefix + (row.aPath || row.path)
  })
  return list
}
const flatRoutes = flattenTree(routerList, '')

const routers = flatRoutes.map(row => {
  return {
    id: row.id,
    path: row.aPath,
    component: row.component
  }
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: MainView,
    children: routers
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/webapp/error.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // 登录判断
  if (to.path === '/login') {
    next()
    return
  }
  const auth = Vue.prototype._auth
  const userId = auth.getUserId()
  if (!userId) {
    auth.clearToken()
    auth.userInfo = null
    router.replace('/login')
  } else {
    const res = await axios.get('/api/current_user')
    if (res.data.payload.orgId === 0 && res.data.payload.orgType === 2) {
      next()
    } else {
      const { data } = await axios.get('/operation/v1/user/current')
      const orga = data.result.orgas.find(row => row.id === 0)
      if (orga) {
        // 更新 token
        const payload = {
          orgId: orga.id,
          orgType: 2
        }
        const member = data.result.members.find(row => row.orga_id === orga.id)
        const headers = {
          orgId: orga.id,
          orgType: 2,
          data_range: member.data_range
        }
        if (member.dep_id) {
          headers.dep_id = member.dep_id
        }
        const res1 = await axios.post('/api/refresh_token', { payload: { orgType: 1, orgId: data.result.userId, operation: payload }, headers: { orgType: 1, orgId: data.result.userId, operation: headers } })
        window._auth.setToken(res1.data.access_token)
        auth.userInfo = data.result
        // window.location.reload()
      } else {
        MessageBox.confirm('您不是运营平台用户，不能进入此管理平台', {
          title: '提示',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        }).finally(() => {
          window.location.href = '//mall.ftling.com'
        })
      }
    }
    // axios.get('/operation/v1/user/current').then(({ data }) => {
    //   if (!data.result) {
    //     window.location.href = '#/login'
    //     return
    //   }
    //   auth.userInfo = data.result
    //   next()
    // }).catch(() => {
    //   window.location.href = '#/login'
    // })
  }
})

export default router
